import { Route, Routes } from 'react-router-dom';
import ApplicationDetailPage from './ApplicationDetailPage';
import ApplicationGroupDetailPage from './ApplicationGroupDetailPage';
import ApplicationGroupsSuspendedPage from './ApplicationGroupsSuspendedPage';
import ApplicationSceneDetailPage from './ApplicationSceneDetailPage';
import ApplicationsPage from './ApplicationsPage';
import ApplicationsSuspendedPage from './ApplicationsSuspendedPage';
import ScenesPage from './ScenesPage';

const ApplicationsRoute = () => {
  return (
    <Routes>
      <Route path="/suspended" element={<ApplicationsSuspendedPage />} />
      <Route
        path="/groups/suspended"
        element={<ApplicationGroupsSuspendedPage />}
      />
      <Route path="/groups/:groupId" element={<ApplicationGroupDetailPage />} />
      <Route
        path="/scenes/:applicationSceneId"
        element={<ApplicationSceneDetailPage />}
      />
      <Route path="/scenes" element={<ScenesPage />} />
      <Route path="/:applicationId" element={<ApplicationDetailPage />} />

      <Route index element={<ApplicationsPage />} />
    </Routes>
  );
};

export default ApplicationsRoute;
