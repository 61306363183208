import { Button, Link, Typography, useTheme } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import certificate from '../../../assets/qr/certificate.png';
import nameTag from '../../../assets/qr/name-tag.png';
import cprAid from '../../../assets/qr/performing-cpr-first-aid-clipart.png';

import { Flex } from '../../../base/components/Flex';
import FormikCheckBox from '../../../base/components/form/FormikCheckBox';
import FormikForm from '../../../base/components/form/FormikForm';
import FormikTextField from '../../../base/components/form/FormikTextField';
import { LaunchPlaylistTemplateQrDocument } from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import i18n from '../../../i18n/i18n';
import { ClientLayout } from '../../../layouts';
import ClientLoginLogo from '../Login/ClientLoginLogo';

type Step = 'name' | 'email' | 'launch' | 'done';

interface Wizzard {
  step: Step;
  nameForm: NameForm;
  emailForm: EmailForm;
}

interface NameForm {
  firstName: string;
  lastName: string;
  gdpr: boolean;
}

interface EmailForm {
  email: string;
}
const LaunchPlaylistQrPage = () => {
  const { id } = useParams<{ id: string }>();
  const [wizzard, setWizzard] = useState<Wizzard>({
    step: 'name',
    nameForm: { firstName: '', lastName: '', gdpr: false },
    emailForm: { email: '' },
  });
  const { palette } = useTheme();
  const { _t } = useTranslationPrefix('LaunchPlaylistQrPage');

  const [launchMutation, { loading: launching }] = useMutationWithSnackbar(
    'LaunchPlaylistQrPage.launchMutation',
    LaunchPlaylistTemplateQrDocument,
    {
      onCompleted: () => {
        setWizzard((w) => ({ ...w, step: 'done' }));
        setTimeout(() => {
          setWizzard((w) => ({ ...w, step: 'launch' }));
        }, 30 * 1000);
      },
    },
  );

  const handleNamesSubmit = async (
    values: NameForm,
    helpers: FormikHelpers<NameForm>,
  ) => {
    setWizzard((w) => ({ ...w, step: 'email', nameForm: values }));
  };

  const handleEmailSubmit = async (
    values: EmailForm,
    helpers: FormikHelpers<EmailForm>,
  ) => {
    setWizzard((w) => ({ ...w, step: 'launch', emailForm: values }));
  };

  const handleEmailskip = () => {
    setWizzard((w) => ({ ...w, step: 'launch', emailForm: { email: '' } }));
  };

  const handleLaunchClick = () => {
    launchMutation({
      variables: {
        input: {
          id: id!,
          firstName: wizzard.nameForm.firstName,
          lastName: wizzard.nameForm.lastName,
          email: wizzard.emailForm.email || null,
          languageCode: i18n.language,
        },
      },
    });
  };
  return (
    <ClientLayout>
      <Flex flexDirection="column" alignItems="center" flexGrow={1}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth="80vw"
          width="100%"
          flexGrow={1}
        >
          <ClientLoginLogo
            color={palette.text.secondary}
            sx={{ px: 8, pb: 5 }}
          />
          {wizzard.step === 'name' && (
            <Flex flexDirection="column" gap={3} alignItems="center">
              <Typography>
                {_t('enterNameFirst', 'Please enter your name first')}
              </Typography>
              <img src={nameTag} alt="Name tag" style={{ width: '20ch' }} />
              <Formik<NameForm>
                initialValues={wizzard.nameForm}
                onSubmit={handleNamesSubmit}
                validationSchema={getNameStepValidationSchema(_t)}
              >
                {({ isSubmitting, isValid, touched, errors }) => (
                  <FormikForm>
                    <Flex flexDirection="column" gap={3}>
                      <FormikTextField
                        fullWidth
                        name="firstName"
                        variant="outlined"
                        label={_t('firstName', 'First name')}
                      />
                      <FormikTextField
                        fullWidth
                        name="lastName"
                        variant="outlined"
                        label={_t('lastName', 'Last name')}
                      />

                      <FormikCheckBox
                        name="gdpr"
                        label={_t('gdprAgreement', 'GDPR agreement')}
                      />

                      <Button
                        id="qr-next"
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        {_t('next', 'Next')}
                      </Button>
                    </Flex>
                  </FormikForm>
                )}
              </Formik>
            </Flex>
          )}

          {wizzard.step === 'email' && (
            <Flex flexDirection="column" gap={3} alignItems="center">
              <Typography>
                {_t('enterEmail', 'Enter email where certificate will be sent')}
              </Typography>
              <img
                src={certificate}
                alt="Certificate"
                style={{ width: '20ch' }}
              />
              <Formik<EmailForm>
                initialValues={wizzard.emailForm}
                onSubmit={handleEmailSubmit}
                validationSchema={getEmailStepValidationSchema(_t)}
              >
                {({ isSubmitting, isValid, touched, errors }) => (
                  <FormikForm>
                    <Flex flexDirection="column" gap={3}>
                      <FormikTextField
                        fullWidth
                        name="email"
                        variant="outlined"
                        label={_t('email', 'E-mail')}
                      />
                      <Link
                        component="button"
                        id="qr-skip"
                        onClick={handleEmailskip}
                        color="grey.500"
                      >
                        {_t('skip', 'Skip for now')}
                      </Link>

                      <Button
                        id="qr-next"
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        {_t('next', 'Next')}
                      </Button>
                    </Flex>
                  </FormikForm>
                )}
              </Formik>
            </Flex>
          )}

          {wizzard.step === 'launch' && (
            <Flex flexDirection="column" gap={3} alignItems="center">
              <Typography>
                {_t('getReadyToLaunch', 'Launch training app in VR headset')}
              </Typography>
              <img src={cprAid} alt="CPR" style={{ width: '30ch' }} />
              <Button
                id="qr-launch"
                size="large"
                color="primary"
                variant="contained"
                disabled={launching}
                onClick={handleLaunchClick}
              >
                {_t('launch', 'Launch')}
              </Button>
            </Flex>
          )}

          {wizzard.step === 'done' && (
            <Flex flexDirection="column" gap={3} alignItems="center">
              <Typography>
                {_t('putHeadsetOn', 'Put on VR headset app is launching')}
              </Typography>
              <img src={cprAid} alt="CPR" style={{ width: '30ch' }} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </ClientLayout>
  );
};

const getNameStepValidationSchema = (t: TFunction) =>
  Yup.object({
    firstName: Yup.string()
      .typeError(t('firstNameRequired', 'First name is required'))
      .required(t('firstNameRequired', 'First name is required')),
    lastName: Yup.string()
      .typeError(t('lastNameRequired', 'Last name is required'))
      .required(t('lastNameRequired', 'Last name is required')),
    gdpr: Yup.bool()
      .typeError(t('gdprRequired', 'GDPR agreement is required'))
      .required(t('gdprRequired', 'GDPR agreement is required'))
      .oneOf([true], t('gdprRequired', 'GDPR agreement is required')),
  });

const getEmailStepValidationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string()
      .typeError(t('emailRequired', 'Email is required'))
      .email(t('emailInvalid', 'Email address is invalid'))
      .required(t('emailRequired', 'Email is required')),
  });

export default LaunchPlaylistQrPage;
