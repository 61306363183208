import { Button, MenuItem } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useContext } from 'react';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikDateTimePicker from '../../../../base/components/form/FormikDateTimePicker';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikSelect from '../../../../base/components/form/FormikSelect';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import { VrDevicesContext } from '../../../../base/components/VrDevicesProvider/VrDevicesProvider';
import { CreatePlaylistTemplateQrDocument } from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../../hooks/useTranslationPrefix';
import useCompany from '../../../../lib/useCompany';

interface FormData {
  vrDeviceId: string;
  additionalParams: string;
  validTo: Date | null;
}

interface Props {
  playlistTemplateId: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const PlaylistTemplateQrDialog = ({
  playlistTemplateId,
  open,
  onClose,
  onSuccess,
}: Props) => {
  const { _t } = useTranslationPrefix('PlaylistTemplateQRsPage');
  const { company } = useCompany();
  const { boxDevices } = useContext(VrDevicesContext);
  const devices = boxDevices || [];

  const [createMutation] = useMutationWithSnackbar(
    'PlaylistTemplateQRsPage.createMutation',
    CreatePlaylistTemplateQrDocument,
    {
      onCompleted: () => {
        onSuccess?.();
      },
    },
  );

  const handleSubmit = async (
    values: FormData,
    helpers: FormikHelpers<FormData>,
  ) => {
    await createMutation({
      variables: {
        input: {
          companyId: company!.id,
          playlistTemplateId: playlistTemplateId,
          vrDeviceId: values.vrDeviceId,
          validTo: values.validTo?.toISOString() || null,
          additionalParams: values.additionalParams,
        },
      },
    });
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={_t('dialog.title', 'Add new QR code')}
      disableEnforceFocus
    >
      <>
        <Formik<FormData>
          initialValues={{
            vrDeviceId: '',
            additionalParams: `{"anneName": "01"}`,
            validTo: null,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={getValidationSchema(_t)}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <Flex flexDirection="column" gap={3}>
                <FormikSelect
                  fullWidth
                  name="vrDeviceId"
                  label={_t('dialog.vrDeviceId', 'VR Device')}
                >
                  {devices?.map((device) => (
                    <MenuItem key={device.id} value={device.id}>
                      {device.name}
                    </MenuItem>
                  ))}
                </FormikSelect>
                <FormikDateTimePicker
                  name="validTo"
                  label={_t('dialog.validTo', 'Valid to')}
                  disablePast={true}
                />
                <FormikTextField
                  fullWidth
                  name="additionalParams"
                  label={_t(
                    'dialog.additionalParams',
                    'Additional params (json)',
                  )}
                  multiline
                  minRows={3}
                  maxRows={8}
                />

                <Flex
                  justifyContent="center"
                  mt={1}
                  gap={3}
                  flexDirection={{ xs: 'column-reverse', md: 'row' }}
                >
                  <Button
                    id="create-playlist-folder-cancel"
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    {_t('dialog.cancel', 'Cancel')}
                  </Button>
                  <Button
                    id="create-playlist-folder-confirm"
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {_t('dialog.submit', 'Save')}
                  </Button>
                </Flex>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </>
    </BaseDialog>
  );
};

export default PlaylistTemplateQrDialog;

const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    vrDeviceId: Yup.string().required(
      _t('dialog.vrDeviceIdRequired', 'Select VR Device'),
    ),
    additionalParams: Yup.string()
      .required(
        _t('dialog.additionalParamsRequired', 'Additional params are required'),
      )
      .test(
        'json',
        _t('dialog.additionalParamsInvalid', 'Invalid json'),
        (value) => {
          try {
            JSON.parse(value || '');
            return true;
          } catch (error) {
            return false;
          }
        },
      ),
  });
