import AdbIcon from '@mui/icons-material/Adb';
import AppsIcon from '@mui/icons-material/Apps';
import BugReportIcon from '@mui/icons-material/BugReport';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ReactComponent as Archived } from '../../assets/svg/archive-solid.svg';
import { ReactComponent as Book } from '../../assets/svg/book-solid.svg';
import { ReactComponent as Landmark } from '../../assets/svg/landmark-solid.svg';
import { ReactComponent as UserFriends } from '../../assets/svg/user-friends-solid.svg';
import { ReactComponent as VrCardboard } from '../../assets/svg/vr-cardboard-solid.svg';
import { useUser } from '../../base/components/UserProvider/useUserHook';
import { UserPermission } from '../../graphql/graphql-operations';
import { DrawerContent } from '../../layouts/AdminLayout/types';
import LiveSessionsProvider from '../components/LiveSessions/LiveSessionsProvider';
import NotFoundDetail from '../components/NotFoundDetail/NotFoundDetail';
import ApplicationsRoute from './Applications/ApplicationsRoute';
import ArchivedRoute from './Archived/ArchivedRoute';
import CompaniesRoute from './Companies/CompaniesRoute';
import DebugRoute from './Debug/DebugRoute';
import ManualsRoute from './Manuals/ManualsRoute';
import PatientsRoute from './Patients/PatientsRoute';
import OSUpdatesRoute from './SystemUpdates/OSUpdatesRoute';
import { VrDevicesRoute } from './VrDevices';
import { VrSessionRoute } from './VrSession';

const AppLayout = lazy(() => import('../../layouts/AdminLayout/AdminLayout'));

const AdminContent = () => {
  const { t } = useTranslation();
  const { user, hasPermission, hasAnyPermission } = useUser();
  const drawerContent: DrawerContent = { items: [] };
  const children: JSX.Element[] = [];

  if (hasPermission(UserPermission.MANAGE_COMPANIES)) {
    drawerContent.items.push({
      label: t('drawer.companies.label', 'Clients'),
      linkTo: `companies`,
      icon: Landmark,
    });
  } else if (hasPermission(UserPermission.READ_MY_USERDATA)) {
    drawerContent.items.push({
      label: t('drawer.myCompany.label', 'Company'),
      linkTo: `companies/${user?.companyId}`,
      icon: Landmark,
    });
  }

  if (hasPermission(UserPermission.READ_MY_USERDATA)) {
    children.push(
      <Route
        key="companies"
        path="/companies/*"
        element={<CompaniesRoute />}
      />,
    );
  }

  if (hasPermission(UserPermission.MANAGE_APPLICATIONS)) {
    drawerContent.items.push({
      label: t('drawer.applications.label', 'Apps'),
      linkTo: `applications`,
      icon: AppsIcon,
    });

    drawerContent.items.push({
      label: t('drawer.scenes.label', 'Scenes'),
      linkTo: `applications/scenes`,
      icon: PermMediaIcon,
    });
    children.push(
      <Route
        key="applications"
        path="/applications/*"
        element={<ApplicationsRoute />}
      />,
    );
  }
  if (hasPermission(UserPermission.READ_COMPANY_VR_DEVICES)) {
    drawerContent.items.push({
      label: t('drawer.vrDevices.label', 'Headsets'),
      linkTo: `vr-devices`,
      icon: VrCardboard,
    });
    children.push(
      <Route
        key="vr-devices"
        path="/vr-devices/*"
        element={<VrDevicesRoute />}
      />,
    );
  }
  if (hasPermission(UserPermission.MANAGE_VR_DEVICE_OS_UPDATES)) {
    drawerContent.items.push({
      label: t('drawer.osUpdate.label', 'Updates'),
      linkTo: `os-updates`,
      icon: AdbIcon,
    });
    children.push(
      <Route
        key="osUpdates"
        path="/os-updates/*"
        element={<OSUpdatesRoute />}
      />,
    );
  }
  if (hasAnyPermission(UserPermission.MANAGE_COMPANY_PATIENTS)) {
    drawerContent.items.push({
      label: t('drawer.patients.label', 'PATIENTS'),
      linkTo: `patients`,
      icon: UserFriends,
    });
    children.push(
      <Route key="patients" path="/patients/*" element={<PatientsRoute />} />,
    );
  }
  if (hasAnyPermission(UserPermission.MANAGE_COMPANY_PATIENTS)) {
    drawerContent.items.push({
      label: t('drawer.archived.label', 'Archived'),
      linkTo: `archived`,
      icon: Archived,
    });
    children.push(
      <Route key="archived" path="/archived/*" element={<ArchivedRoute />} />,
    );
  }
  if (hasPermission(UserPermission.READ_MANUALS)) {
    drawerContent.items.push({
      label: t('drawer.manual.label', 'Manual'),
      linkTo: `manuals`,
      icon: Book,
    });
    children.push(
      <Route key="manual" path="/manuals/*" element={<ManualsRoute />}></Route>,
    );
  }
  if (hasPermission(UserPermission.MANAGE_COMPANY_SESSIONS)) {
    children.push(
      <Route
        key="vr-session"
        path="/vr-session/*"
        element={<VrSessionRoute />}
      />,
    );
  }
  if (hasPermission(UserPermission.DEBUG)) {
    drawerContent.items.push({
      label: t('drawer.debug.label', 'Debug'),
      linkTo: `debug`,
      icon: BugReportIcon,
    });
    children.push(
      <Route key="debug" path="/debug/*" element={<DebugRoute />} />,
    );
  }

  return hasPermission(UserPermission.LOGIN_ADMIN) ? (
    <LiveSessionsProvider>
      <AppLayout drawerContent={drawerContent}>
        <Suspense fallback={<div />}>
          <Routes>
            {children}
            <Route index element={<Navigate to="/admin/vr-devices" />} />
            <Route path="*" element={<NotFoundDetail />} />
          </Routes>
        </Suspense>
      </AppLayout>
    </LiveSessionsProvider>
  ) : (
    <div>{t('common.unauthorized', 'Unauthorized')}</div>
  );
};

export default AdminContent;
