import { FormHelperText } from '@mui/material';
import { Field } from 'formik';
import { CheckboxWithLabel, CheckboxWithLabelProps } from 'formik-mui';

interface Props {
  name: string;
  label: string;
  className?: string;
}

interface CheckBoxProps extends CheckboxWithLabelProps {}

const CheckBoxWithError = ({ ...props }: CheckBoxProps) => {
  const fieldName = props.field.name;
  const touched = !!props.form.touched[fieldName];
  const error = props.form.errors[fieldName];
  return (
    <div>
      <CheckboxWithLabel {...props}></CheckboxWithLabel>
      {touched && error && (
        <FormHelperText
          error={true}
          sx={{ position: 'initial', transform: 'none' }}
        >
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

const FormikCheckBox = ({ name, label, className }: Props) => {
  return (
    <div className={className}>
      <Field
        component={CheckBoxWithError}
        name={name}
        type="checkbox"
        color="primary"
        Label={{ label: label }}
      />
    </div>
  );
};

export default FormikCheckBox;
