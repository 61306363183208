import { useMutation, useQuery } from '@apollo/client';
import i18next from 'i18next';
import { useUser } from '../base/components/UserProvider/useUserHook';
import {
  ChangeLanguageDocument,
  LanguagesDocument,
} from '../graphql/graphql-operations';

export function useChangeUserLanguage(onCompleted?: () => void) {
  const language = i18next.languages[0];
  const { data } = useQuery(LanguagesDocument);
  const { user } = useUser();
  const [changeLanguage, { client }] = useMutation(ChangeLanguageDocument, {
    onCompleted: (data) => {
      const userLang = data.changeLanguage.languageCode;
      if (userLang !== i18next.languages[0]) {
        i18next.changeLanguage(userLang);
      }
      client.resetStore();
      if (onCompleted) {
        onCompleted();
      }
    },
  });

  const handleLanguageChange = (lang?: string) => {
    const languageCode = lang
      ? lang
      : language === 'en'
      ? 'cs'
      : language === 'cs'
      ? 'de'
      : 'en';
    const isSupported = data?.languages.find((l) => l.code === languageCode);
    setLangCookie(languageCode);
    if (isSupported && user) {
      changeLanguage({
        variables: {
          changeLanguageInput: {
            languageCode,
          },
        },
      });
    } else {
      i18next.changeLanguage(languageCode);
    }
  };

  return [handleLanguageChange];
}

export const setLangCookie = (languageCode: string) => {
  document.cookie = `languageCode=${languageCode}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure=false;`;
};
