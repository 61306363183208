import { useQuery } from '@apollo/client';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import {
  InvalidatePlaylistTemplateQrDocument,
  PlaylistTemplateQrsDocument,
} from '../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import PlaylistTemplateQRCard from './components/PlaylistTemplateQRCard';
import PlaylistTemplateQrDialog from './components/PlaylistTemplateQrDialog';

const PlaylistTemplateQRsPage = () => {
  const { _t } = useTranslationPrefix('PlaylistTemplateQRsPage');
  const { templateId } = useParams<{ templateId: string }>();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const [invalidateConfirmOpen, setInvalidateConfirmOpen] = useState<
    string | null
  >(null);

  const { data, refetch, error, loading } = useQuery(
    PlaylistTemplateQrsDocument,
    {
      variables: {
        input: {
          playlistTemplateId: templateId,
        },
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  useQueryErrorSnackbar(error, refetch);

  const [invalidate] = useMutationWithSnackbar(
    'PlaylistTemplateQRCard.invalidateMutation',
    InvalidatePlaylistTemplateQrDocument,
    {
      onCompleted: () => {
        refetch();
        setInvalidateConfirmOpen(null);
      },
    },
  );

  const handleInvalidateConfirm = async () => {
    if (invalidateConfirmOpen) {
      await invalidate({
        variables: {
          input: {
            id: invalidateConfirmOpen,
          },
        },
      });
    }
  };

  return loading || !data?.playlistTemplateQrs ? (
    <Loader />
  ) : (
    <ContentWrapper>
      <PageHeader title={_t('title', 'Playlist QR codes')} back="./..">
        <Button
          id="create-qr-dialog"
          variant="contained"
          onClick={() => setCreateDialogOpen(true)}
          startIcon={<AddRoundedIcon />}
        >
          {_t('createQr', 'Add QR')}
        </Button>
      </PageHeader>

      <Flex gap={2} flexDirection="column">
        {data?.playlistTemplateQrs?.map((qr) => (
          <PlaylistTemplateQRCard
            key={qr.id}
            qrCode={qr}
            onInvalidateClick={() => setInvalidateConfirmOpen(qr.id)}
          />
        ))}
      </Flex>
      <PlaylistTemplateQrDialog
        playlistTemplateId={templateId!}
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSuccess={() => {
          refetch();
          setCreateDialogOpen(false);
        }}
      />
      <ConfirmDialog
        title={_t('invalidateConfirm', 'Invalidate QR code?')}
        open={!!invalidateConfirmOpen}
        onClose={() => setInvalidateConfirmOpen(null)}
        onConfirm={handleInvalidateConfirm}
        confirmButtonColor="error"
        confirmLabel={_t('invalidate', 'Invalidate')}
        confirmButtonStartIcon={<DeleteForeverIcon />}
      />
    </ContentWrapper>
  );
};

export default PlaylistTemplateQRsPage;
