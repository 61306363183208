import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  GetUserDetailDocument,
  StreamType,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import useStreamType from '../../../lib/useStreamType';
import ContentWrapper from '../../components/ContentWrapper';
import { Loader } from '../../components/Loader';
import PageHeader from '../../components/PageHeader';
import UserDetailHeaderCard from './components/UserDetailHeaderCard';
import UserDetailList from './components/UserDetailList';
import UserDetailTabs, { UserDetailFilter } from './components/UserDetailTabs';

const UserDetailPage = () => {
  const trainingVariant = useStreamType() === StreamType.TRAINING;
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = trainingVariant
    ? UserDetailFilter.TRAINING
    : UserDetailFilter.HISTORY;
  const activeTab = (searchParams.get('tab') as UserDetailFilter) || defaultTab;

  const { userId } = useParams<{ userId?: string }>();
  const { data, loading, error, refetch } = useQuery(GetUserDetailDocument, {
    variables: {
      userId,
    },
    skip: !userId,
  });
  useQueryErrorSnackbar(error, refetch);

  const { _t } = useTranslationPrefix('UserDetailPage');

  const handleTabChange = useCallback(
    (newTab: UserDetailFilter) => {
      const params = Object.fromEntries(searchParams);
      setSearchParams(
        {
          ...params,
          tab: newTab,
        },
        { replace: true },
      );
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    if (!searchParams.has('tab')) {
      handleTabChange(defaultTab);
    }
  }, [searchParams, handleTabChange, defaultTab]);

  return loading ? (
    <Loader />
  ) : (
    <ContentWrapper>
      <PageHeader back="../" title={_t('title', 'User profile')}></PageHeader>
      <UserDetailHeaderCard
        user={data?.user}
        sx={{ mb: 2 }}
        trainingVariant={trainingVariant}
      />
      <UserDetailTabs
        onChange={handleTabChange}
        activeTab={activeTab}
        user={data?.user}
      />
      <Box sx={{ mt: 2 }}>
        <UserDetailList activeTab={activeTab} userId={userId} />
      </Box>
    </ContentWrapper>
  );
};

export default UserDetailPage;
