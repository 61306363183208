import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ApplicationStatus } from '../../../graphql/graphql-operations';
import { SectionPaper } from '../../components/SectionPaper';
import { TopBar } from '../../components/TopBar';
import ApplicationPageActions from './ApplicationPageActions';
import ApplicationsTable from './ApplicationsTable';

const ApplicationsPage: React.VFC = () => {
  const { t } = useTranslation();
  const { spacing } = useTheme();
  return (
    <>
      <TopBar
        style={{ marginTop: spacing(5.5) }}
        leftSideText={t('applications.topBar.title', 'APKs')}
        actions={<ApplicationPageActions />}
      />
      <SectionPaper>
        <ApplicationsTable status={ApplicationStatus.ACTIVE} />
      </SectionPaper>
    </>
  );
};

export default ApplicationsPage;
