import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, CardActionArea, CardContent, CardProps } from '@mui/material';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { ImageBackground } from '../../../../base/components/ImageBackground';
import { SelectableCard } from '../../../../base/components/SelectableCard';
import { PlaylistTemplateListFragmentFragment } from '../../../../graphql/graphql-operations';

interface Props {
  template: PlaylistTemplateListFragmentFragment;
  onClick?: (template: PlaylistTemplateListFragmentFragment) => void;
  selected?: boolean;
  children?: React.ReactNode;
  wideVariant?: boolean;
  sx?: CardProps['sx'];
  onRemove?: (id: string) => void;
}

const PlaylistTemplateCard = ({
  template,
  onClick,
  selected,
  children,
  wideVariant,
  onRemove,
  sx,
}: Props) => {
  const imageUrl = template.items.find((i) => i.scene.imageUrl)?.scene
    ?.imageUrl;

  const renderCardContent = () => {
    return (
      <CardContent>
        <Flex
          flexDirection={wideVariant ? { xs: 'column', sm: 'row' } : 'column'}
        >
          <ImageBackground
            src={imageUrl}
            sx={{
              aspectRatio: '16/10',
              width: wideVariant ? '30%' : '100%',
            }}
          >
            <Flex justifyContent="space-between" alignItems="flex-start">
              <BaseTypography
                variant="h5"
                // ?? in design text was wrapped into lines
                //whiteSpace="nowrap"
                // overflow="hidden"
                // textOverflow="ellipsis"
                color="white.main"
              >
                {template.name}
              </BaseTypography>
              {onRemove && (
                <Button
                  id="playlist-template-card-delete"
                  aria-label="back"
                  color="error"
                  size="small"
                  sx={{
                    borderRadius: '50%',
                    padding: 1.5,
                    minWidth: 'auto',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemove(template.id);
                  }}
                >
                  <CloseRoundedIcon color="white" fontSize="medium" />
                </Button>
              )}
            </Flex>
          </ImageBackground>
          <Flex
            flexDirection="column"
            flexGrow={1}
            pl={wideVariant ? { xs: 0, sm: 3 } : 0}
            mt={2}
          >
            {children}
          </Flex>
        </Flex>
      </CardContent>
    );
  };

  return (
    <SelectableCard
      id={`playlist-template-${template.id}`}
      selected={selected}
      sx={sx}
    >
      {onClick ? (
        <CardActionArea onClick={() => onClick(template)}>
          {renderCardContent()}
        </CardActionArea>
      ) : (
        renderCardContent()
      )}
    </SelectableCard>
  );
};

export default PlaylistTemplateCard;
