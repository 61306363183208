import { GetIceServerConfigQuery } from '../../../graphql/graphql-operations';
import { createImageHandler } from './handleImageData';

type offerListener = (
  offer: RTCSessionDescriptionInit,
) => Promise<string | null>;

export interface ConnectionHolder {
  state: {
    lastUpdate: Date;
  };
  config: RTCConfiguration;
  peerConnection: RTCPeerConnection;
  prepareOffer: (newOfferCallback: offerListener) => void;
  addRemoteCandidate: (
    candidate: RTCIceCandidate | RTCIceCandidateInit,
  ) => void;
  handleAnswer: (
    answer: RTCSessionDescription | RTCSessionDescriptionInit,
  ) => void;
}

interface MessageData {
  EmitType: number;
  DataString: string;
  DataByte: number[];
}

export const prepareConnection = (
  configFromServer: GetIceServerConfigQuery,
  onNewCandidate: (sessionId: string, candidate: RTCIceCandidate) => void,
  onNewImage: (imageSrcValue: string) => void,
): ConnectionHolder => {
  const state: ConnectionHolder['state'] = {
    lastUpdate: new Date(),
  };
  let sessionId: string | null = null;
  const imageHandler = createImageHandler();
  const config = prepareConfig(configFromServer);
  const peerConnection = new RTCPeerConnection(config);
  let offerListner: offerListener;

  peerConnection.addEventListener('icecandidate', (e) => {
    const { candidate } = e as RTCPeerConnectionIceEvent;
    if (!sessionId) {
      console.error('WebRtc session id was expected');
      return;
    }

    candidate && onNewCandidate && onNewCandidate(sessionId, candidate);
  });

  peerConnection.addEventListener('datachannel', (e: RTCDataChannelEvent) => {
    const channel = e.channel;

    if (channel.label !== 'fmetp') {
      return;
    }

    channel.binaryType = 'arraybuffer';

    channel.addEventListener(
      'message',
      async (ev: MessageEvent<string | ArrayBuffer>) => {
        let imageSrc;
        if (typeof ev.data === 'string') {
          const data: MessageData = JSON.parse(ev.data);
          imageSrc = imageHandler.handleImageData(
            new Uint8Array(data.DataByte),
          );
        } else {
          const data = new Uint8Array(ev.data);
          imageSrc = imageHandler.handleImageData(new Uint8Array(data));
        }
        if (imageSrc) {
          state.lastUpdate = new Date();
          onNewImage(imageSrc);
        }
      },
    );
  });

  const prepareOffer: ConnectionHolder['prepareOffer'] = async (listener) => {
    offerListner = listener;
    const offer = await peerConnection.createOffer({
      offerToReceiveVideo: false,
      offerToReceiveAudio: false,
    });
    sessionId = offerListner && (await offerListner(offer));

    await peerConnection.setLocalDescription(offer);
    state.lastUpdate = new Date();
  };

  const cachedCandidates: (RTCIceCandidate | RTCIceCandidateInit)[] = [];

  const handleAnswer = async (
    answer: RTCSessionDescription | RTCSessionDescriptionInit,
  ) => {
    if (peerConnection.signalingState === 'stable') {
      return;
    }
    await peerConnection.setRemoteDescription(answer);
    cachedCandidates.forEach((candidate) => {
      peerConnection.addIceCandidate(candidate);
    });
    state.lastUpdate = new Date();
  };

  const addRemoteCandidate = (
    candidate: RTCIceCandidate | RTCIceCandidateInit,
  ) => {
    if (!peerConnection || !peerConnection.remoteDescription) {
      cachedCandidates.push(candidate);
    } else {
      peerConnection.addIceCandidate(candidate);
    }
    state.lastUpdate = new Date();
  };

  peerConnection.createDataChannel('fmetp', {
    maxRetransmits: 1,
    negotiated: false,
    ordered: true,
  });

  return {
    state,
    peerConnection,
    config,
    prepareOffer,
    addRemoteCandidate,
    handleAnswer,
  };
};

const prepareConfig = ({
  iceServerConfig: { iceServers },
}: GetIceServerConfigQuery): RTCConfiguration => {
  const result: RTCConfiguration = {};
  if (iceServers) {
    result.iceServers = iceServers.map((from) => {
      const { urls, credential, credentialType, username } = from;
      const to: RTCIceServer = { urls: [...urls] };
      if (credential) {
        to.credential = credential;
      }
      if (credentialType && credentialType === 'password') {
        to.credentialType = credentialType;
      }
      if (username) {
        to.username = username;
      }
      return to;
    });
  }

  return result;
};
