import { useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableCellLink } from '../../../base/components/BaseTable';
import {
  ColumnConfig,
  SimpleTable,
} from '../../../base/components/SimpleTable';
import {
  GetAllApplicationScenesDocument,
  GetAllApplicationScenesQuery,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { SectionPaper } from '../../components/SectionPaper';

type Scene = GetAllApplicationScenesQuery['allApplicationScenes'][0];

const AllScenesTable = () => {
  const { data, loading, error, refetch } = useQuery(
    GetAllApplicationScenesDocument,
  );
  useQueryErrorSnackbar(error, refetch);
  const scenes = data?.allApplicationScenes || [];
  const theme = useTheme();
  const { t } = useTranslation();
  const columns: ColumnConfig<Scene>[] = [
    {
      id: 'name_t',
      defaultTrans: 'Name',
      component: (row: Scene) => {
        const version = row.applicationVersionId;
        return (
          <TableCellLink
            to={`/admin/applications/scenes/${row.id}?applicationVersionId=${version}`}
            label={row.name_t}
          />
        );
      },
    },

    /*{
      id: 'parameters',
      defaultTrans: 'Parameters',
      component: (row: Scene) => {
        return (
          <TableCell>
            {row.parameters.length +
              ' | ' +
              row.parameters
                .map((param) => `${param.name_t} - ${param.values.length}`)
                .join(' | ')}
          </TableCell>
        );
      },
    },*/
    {
      id: 'enabled' as const,
      defaultTrans: 'Status',
      getLabel: (enabled: boolean) =>
        enabled
          ? t('applicationScenes.table.enabledValue', 'Enabled')
          : t('applicationScenes.table.disabled', 'Disabled'),
    },
  ];

  return (
    <SectionPaper style={{ marginTop: theme.spacing(2) }}>
      <SimpleTable<Scene>
        id="application-scenes"
        translationPrefix="applicationScenes.table"
        rowId="id"
        isLoading={loading}
        data={[...scenes]}
        columns={columns}
        searchConfig={{
          filterBy: 'name_t',
          defaultTitle: 'Scene list',
          defaultPlaceholder: 'Search {{count}} scenes',
        }}
      />
    </SectionPaper>
  );
};

export default AllScenesTable;
