import { useTranslation } from 'react-i18next';
import { TopBar } from '../../components/TopBar';
import AllScenesTable from './AllScenesTable';

const ScenesPage: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <>
      <TopBar leftSideText={t('scenes.topBar.title', 'Scenes')} />
      <AllScenesTable />
    </>
  );
};

export default ScenesPage;
