import { DateTimePickerProps, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import csLocale from 'date-fns/locale/cs';
import enLocale from 'date-fns/locale/en-US';
import { Field } from 'formik';
import { DateTimePicker } from 'formik-mui-lab';
import { useTranslation } from 'react-i18next';

interface Props
  extends Omit<
    DateTimePickerProps,
    'value' | 'error' | 'onChange' | 'renderInput'
  > {
  name: string;
}

const localeMap: Record<string, object> = {
  en: enLocale,
  cs: csLocale,
};

const FormikDateTimePicker = ({ name, ...restProps }: Props) => {
  const { i18n } = useTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[i18n.language]}
    >
      <Field component={DateTimePicker} name={name} {...restProps} />
    </LocalizationProvider>
  );
};

export default FormikDateTimePicker;
