import { StateMethods } from '@hookstate/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BaseTypography } from '../../base/components/BaseTypography';
import { Flex } from '../../base/components/Flex';
import HasPermission from '../../base/components/UserProvider/HasPermission';
import { UserPermission } from '../../graphql/graphql-operations';
import { AppBarHeight } from './AppBar';
import { DrawerContent } from './types';

export const DRAWER_WIDTH = '6rem';
interface Props {
  showFeedback: StateMethods<boolean>;
  drawerContent: DrawerContent;
}

const Drawer = ({ drawerContent, showFeedback }: Props) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { items } = drawerContent;
  const location = useLocation();
  const navigate = useNavigate();

  const isItemSelected = (linkTo: string) => {
    const slashes = linkTo.split('/').length - 1;
    const locationSlashes = location.pathname.split('/').length - 3;

    return locationSlashes === slashes && location.pathname.includes(linkTo);
  };

  const backButtonDisabled = drawerContent.items.some(
    ({ linkTo }) => linkTo === location.pathname,
  );

  return (
    <Aside>
      <StyledDrawer
        variant="permanent"
        ModalProps={{
          keepMounted: true,
        }}
      >
        <AppBarHeight />
        <Navigation>
          <>
            <Flex justifyContent="center" paddingY={2}>
              <BackButton
                id="drawer-back"
                aria-label="back"
                disabled={backButtonDisabled}
                onClick={() => navigate(-1)}
                size="large"
              >
                <ArrowBackIcon fontSize="inherit" />
              </BackButton>
            </Flex>
            <Box style={{ overflow: 'auto' }}>
              {items.map(({ linkTo, label, icon: Icon }) => {
                const isSelected = isItemSelected(linkTo);
                const iconColor = isSelected
                  ? palette.primary.main
                  : palette.text.secondary;
                return (
                  <StyledListItem
                    key={linkTo}
                    // @ts-ignore
                    component={Link}
                    to={linkTo}
                    selected={isSelected}
                  >
                    {Icon && (
                      <ListItemIcon
                        style={{
                          fill: iconColor,
                          color: iconColor,
                          minWidth: '1.75rem',
                          maxWidth: '1.75rem',
                        }}
                      >
                        <Icon />
                      </ListItemIcon>
                    )}
                    <ListItemText style={{ flexGrow: 0 }}>
                      <BaseTypography
                        color={isSelected ? 'primary' : 'inherit'}
                        variant="body2"
                        fontWeight="medium"
                        uppercase
                      >
                        {label}
                      </BaseTypography>
                    </ListItemText>
                  </StyledListItem>
                );
              })}
            </Box>
            <Box>
              <HasPermission val={UserPermission.CREATE_FEEDBACK}>
                <StyledListItem onClick={() => showFeedback.set((p) => !p)}>
                  <ListItemIcon style={{ justifyContent: 'center' }}>
                    <SmsOutlinedIcon style={{ fontSize: '1.75rem' }} />
                  </ListItemIcon>
                  <BaseTypography variant="body2" fontWeight="medium" uppercase>
                    {t('drawer.feedback.label', 'Feedback')}
                  </BaseTypography>
                </StyledListItem>
              </HasPermission>
            </Box>
          </>
        </Navigation>
      </StyledDrawer>
    </Aside>
  );
};

export default Drawer;

const Aside = styled('aside')({
  width: DRAWER_WIDTH,
  flexShrink: 0,
});

const StyledDrawer = styled(MuiDrawer)({
  '& .MuiDrawer-paper': {
    borderRight: 'none',
    width: DRAWER_WIDTH,
  },
});

const StyledListItem = styled(ListItemButton)(
  ({ theme: { palette, spacing } }) => ({
    textDecoration: 'none',
    color: palette.text.secondary,
    flexFlow: 'column',
    minHeight: spacing(12),
    justifyContent: 'center',
    '& .Mui-selected': {
      backgroundColor: `${palette.background.default} !important`,
    },
  }),
);

const Navigation = styled('nav')({
  height: '100%',
  display: 'inline-flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
  padding: 0,
});

const BackButton = styled(IconButton)(({ theme: { palette } }) => ({
  color: palette.primary.contrastText,
  backgroundColor: `${palette.primary.main}!important`,
  borderRadius: 40,
  '&.Mui-disabled': {
    opacity: 0,
  },
}));
